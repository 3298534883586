<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>短视频</el-breadcrumb-item>
                <el-breadcrumb-item>威有推广</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="_add">
                <button @click="openAdd">添加推广头像</button>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border>
                <el-table-column prop="image" align="center" label="图片">
                    <template slot-scope="{ row }">
                        <img :src="row.image" style="width: 60%" />
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="tableData_" border style="margin-top: 20px">
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="title_a" align="center" label="描述a"> </el-table-column>
                <el-table-column prop="title_b" align="center" label="描述b"> </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet_(row)">编辑</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加或编辑链接弹框 -->
        <el-dialog
            :title="form.id ? '编辑' : '添加'"
            :visible.sync="dialogShow"
            width="70%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="90px">
                <el-form-item label="图片">
                    <upload-img :src="form.image" @removeSrc="() => (form.image = '')" ref="image"></upload-img>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑推广 -->
        <el-dialog title="编辑" :visible.sync="dialogShow_" width="70%" :show-close="false" :before-close="bfc">
            <el-form :model="form_" label-width="90px">
                <el-form-item label="标题">
                    <el-input v-model.trim="form_.title"></el-input>
                </el-form-item>
                <el-form-item label="描述a">
                    <el-input v-model.trim="form_.title_a"></el-input>
                </el-form-item>
                <el-form-item label="描述b">
                    <el-input v-model.trim="form_.title_b"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_ = false">取 消</el-button>
                <el-button type="primary" @click="yes_">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import uploadImg from "@/components/customs/uploadImg.vue";
import qs from "qs";
export default {
    components: { uploadImg },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
            tableData_: [],
            form_: {},
            dialogShow_: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
        this.tableData_ = await this.getData_();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/video/svprheadlist");
            return data;
        },
        async getData_() {
            let { data } = await axios.get("/api/video/svprlist");
            return data;
        },
        openAdd() {
            this.form = {
                image: "",
                status: 1,
            };
            this.dialogShow = true;
        },
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        async yes() {
            let { form } = this;
            form.image = this.$refs.image.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/video/svprheadup", qs.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/video/svprheadadd", qs.stringify(form));
            }
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/video/svprheaddel", { params: { id } });
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
        },
        // ---
        openSet_(row) {
            this.form_ = { ...row };
            this.dialogShow_ = true;
        },
        async yes_() {
            let { form_ } = this;
            var { data } = await axios.put("/api/video/svprup", qs.stringify(form_));
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData_ = await this.getData_();
            this.dialogShow_ = false;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
